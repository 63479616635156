// TYPOGRAPHY

$primary-font: 'PT Sans', sans-serif;
$font-system: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

$font-heading: 'Playfair Display', serif;
$font-body: 'PT Sans', sans-serif;

* {
    /* Better Font Rendering */
    // text-rendering: optimizeLegibility; ⚠️ Optional - That Optimize Legibility (slower rendering)
    // text-rendering: optimizeSpeed; ⚠️ Optional - That Optimize Speed (faster rendering)
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $font-body, sans-serif;
    line-height: 1.175;
    // font-size: 16px; ⚠️ Optional - Usually the browsers set this value to 16px (that property is the base of a rem > 1rem = 16px)
}

h1,
.h1 {
    font-size: 3rem;
    font-family: $font-heading, sans-serif;
}

h2,
.h2 {
    font-size: 2.4rem;
    font-family: $font-heading, sans-serif;
}


h3,
.h3 {
    font-size: 1.75rem;
    font-family: $font-heading, sans-serif;
}

h4,
.h4 {
    font-size: 1.5rem;
    // font-weight: 400;
}

h5,
.h5 {
    font-size: 1.2rem;
    font-family: $font-heading, sans-serif;
    // font-weight: 400;
}

h6,
.h6{
    font-size: 1rem;
    font-family: $font-heading, sans-serif;
    // font-weight: 400;
}

p {
    font-size: 1.6rem;
    line-height: 1.4;
}

a{
    color: currentColor;
    font-family: $font-body, sans-serif;
    font-size: 1.6rem;
}


.title--label{
    font-family: $font-body, sans-serif;
    letter-spacing: .25em;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.title--jumbo{
    font-size: 8rem;
    line-height: 1;

    @include media('<iPadLandscape'){
        font-size: 5.6rem;
    }
}