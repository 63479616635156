@mixin btn-default {
    display: inline-block;
    position: relative;
    padding: {
        top: 0.15em;
        // right: .5em;
        bottom: 0.15em;
        // left: .5em;
    }
    color: currentColor;
    font: {
        family: $font-heading;
        size: 1em;
    }
    text-align: left;
    line-height: 1.1;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    overflow: hidden;

    @include media("<=iPhonePlus") {
        padding: {
            top: 0.25em;
            right: 0.5em;
            bottom: 0.25em;
            left: 0.5em;
        }
        font: {
            size: 2rem;
        }
    }

    &:before {
        // Underline Effect
        content: "";
        display: block;
        position: absolute;
        height: 0.025em;
        min-height: 1px;
        // width: calc(100% - .75em);
        width: 100%;
        bottom: 0;
        background: {
            color: currentColor;
        }
        will-change: transform, transform-origin;
        transform-origin: left;
        transition: transform 0.4s ease;
    }

    // &:after {
    //     // Arrow Indicator
    //     content: "";
    //     display: inline-block;
    //     margin: {
    //         left: 0.8rem;
    //     }
    //     height: 0.5em;
    //     width: 0.5em;
    //     color: inherit;
    //     transform: translateY(0);
    //     vertical-align: -0.05em;
    //     background: {
    //         image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3294 0.92548H10.3295V0.565674H0.254907L0.254907 0.92548L9.7156 0.92548L0 10.7465L0.255791 10.9996L9.96956 1.18037L9.96956 11H10.3294V0.92548Z' fill='currentColor'/%3E%3C/svg%3E");
    //         size: contain;
    //         position: center;
    //         repeat: no-repeat;
    //     }
    //     will-change: transform;
    //     transition: transform 0.4s ease;
    // }

    // &.light {
    //     &:after {
    //         background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3294 0.92548H10.3295V0.565674H0.254907L0.254907 0.92548L9.7156 0.92548L0 10.7465L0.255791 10.9996L9.96956 1.18037L9.96956 11H10.3294V0.92548Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
    //     }
    // }

    // &.dark {
    //     &:after {
    //         background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3294 0.92548H10.3295V0.565674H0.254907L0.254907 0.92548L9.7156 0.92548L0 10.7465L0.255791 10.9996L9.96956 1.18037L9.96956 11H10.3294V0.92548Z' fill='currentColor'/%3E%3C/svg%3E");
    //     }
    // }

    &:hover {
        color: currentColor;

        &:before {
            transform: scaleX(0);
            transform-origin: right;
        }

        &:after {
            transform: translateY(0);
        }

        // If btn-default has also '.inverted' class
        &.inverted {
            &:before {
                transform: scaleX(1);
            }
            &:after {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    // &.long {
    //     span {
    //         height: 2.25em;
    //         white-space: normal;
    //     }
    // }

    // &.long-xl {
    //     span {
    //         height: 3.35em;
    //         white-space: normal;
    //     }
    // }

    &.no-arrow {
        &:after {
            content: none;
        }
    }

    &.no-line {
        &:before {
            content: none;
        }
    }

    &.inverted {
        &:before {
            transform: scaleX(0);
        }
        &:after {
            transform: translateY(100%);
            opacity: 0;
            will-change: transform, opacity;
            transition:
                transform 0.6s ease,
                opacity 0.6s ease;
        }
        &.active {
            &:before {
                transform: scaleX(1);
            }
            &:after {
                transform: rotate(90deg);
                opacity: 1;
            }
        }
    }
}

@mixin btn-cta {
    display: flex;
    align-items: flex-end;
    position: relative;
    letter-spacing: 0.5px;
    width: 18.5rem;
    color: currentColor;
    border: {
        width: 1px;
        style: solid;
        color: currentColor;
    }
    will-change: color, background-color;
    transition:
        color 0.4s ease,
        background-color 0.4s ease;

    @include media(">=iPad10") {
        width: 14.5rem;
    }

    @include media(">=Laptop") {
        width: 21.5rem;
    }

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
        height: 0;
        width: 100%;
    }

    span {
        @include btn-default;

        position: absolute;
        right: 1em;
        bottom: 1em;
        left: 1em;
        padding: {
            right: 0 !important;
            left: 0 !important;
        }
        font-size: 1.5em;
        text-transform: capitalize;
        white-space: normal;
        pointer-events: none;
    }

    &:hover {
        color: $white;
        background-color: $gray-700;

        span {
            @include btn-light;
        }
    }

    &.light {
        color: $white;

        span {
            @include btn-light;
        }

        &:hover {
            color: $gray-900;
            background-color: $white;

            span {
                // @extend .dark;
            }
        }
    }

    &.gold {
        background-color: $gold;
        color: $white;
        border-color: $gold;

        span {
            @include btn-light;
        }

        &:hover {
            background-color: mix(black, $gold, 15%);
        }
    }
}

@mixin btn-kind {
    @include btn-default;

    color: currentColor;
    will-change: color, background-color;
    transition:
        color 0.4s ease,
        background-color 0.4s ease;
    font-size: 4.5rem;

    span {
        white-space: normal;
    }

    &:hover {
        color: currentColor;
    }
}
