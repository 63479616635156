main {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    will-change: width;
    transition: width 0.4s linear;
    z-index: 1;

    @include media(">=iPadLandscape") {
        height: auto;
        overflow: hidden;
    }

    header {
        display: block;
        position: relative;
        flex: 1 1 0;
        background-color: rgba($gray-700, 0.5);
        z-index: z("navigation");

        ul.nav{
            display: flex;
            flex-direction: column;
            flex: 1 1 0;
            
            @include media(">=iPadLandscape") {
                flex-direction: row;
                height: 100%;

                &:hover{
                    li:not(:hover) {
                        span{
                            max-width: 10px;
                            opacity: 0;
                        }

                        figure img{
                            opacity: .2
                        }
                    }
                }
            }

            li{
                flex: 1 1 0;
                height: clamp(300px, 80vh, 500px);
                transition: flex .7s ease-in-out;

                @include media(">=iPadLandscape") {
                    height: auto;
                }

                &:hover{
                    flex: 3 1 0;
                }

                a {
                    display: flex;
                    align-items: end;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    padding: {
                        block: 2em;
                        inline: .5em;
                    }
                    font-size: clamp(4.2rem, 10vw, 4.6rem);
                    box-sizing: border-box;

                    @include media(">=iPadLandscape") {
                        padding: {
                            block: 2em;
                            inline: 2em;
                        }
                    }

                    figure{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: $black;

                        img{
                            display: block;
                            height: 100%;
                            width: 100%;
                            object: {
                                fit: cover;
                                position: center;
                            };
                            opacity: .8;
                            transition: opacity .4s ease-in-out;
                        }
                    }

                    span.btn-default{
                        display: flex;
                        width: 100%;
                        font-size: 1em;
                        overflow: visible;
                        will-change: opacity, max-width;
                        transition: opacity .4s ease-in-out, width .7s ease-in-out;
                        
                        @include media(">=iPadLandscape") {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}
