button {
    cursor: pointer;
}
button {
    padding: {
        right: 0;
        left: 0;
    }
    user-select: none;
}

.btn {
    font-size: 1em;
    border-radius: 0;
    user-select: none;
}

.btn-default {
    @include btn-default;

    display: inline-flex;
    align-items: baseline;
    white-space: normal;
    user-select: none;

    & > span {
        display: flex;
        flex-direction: column;
        // height: 1.2em;
        pointer-events: none;
        overflow: hidden;

        span {
            line-height: 1;
            pointer-events: none;
            transition:
                opacity 0.4s ease,
                transform 0.35s cubic-bezier(0.8, 0, 0.25, 1);

            &:nth-child(2) {
                opacity: 0;
                transform: translateY(0.25em);
            }
        }
    }

    &:hover {
        & > span {
            span {
                &:nth-child(1) {
                    opacity: 0;
                    transform: translateY(calc(-100% - 0.25em));
                }

                &:nth-child(2) {
                    opacity: 1;
                    transform: translateY(-100%);
                }
            }
        }

        &:before {
            transform: scaleX(0);
            transform-origin: right;
        }

        &:after {
            transform: rotate(90deg);
        }
    }
}

[disabled] {
    opacity: 0.25;
    pointer-events: none;
    user-select: none;
}