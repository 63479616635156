footer{
    padding: {
        block: .5em;
        inline: 1em;
    }
    color: $white;
    font-size: clamp(1rem, 1vw, 1.2rem);
    background-color: $gray-800;

    @include media(">=iPadPortrait"){
        padding-inline: 10vw;
        margin-top: auto;
    }
}