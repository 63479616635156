// Book
@font-face {
    font-family: 'Circular Std';
    src: url('../fonts/CircularStd-Book.woff2') format('woff2'),
        url('../fonts/CircularStd-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../fonts/CircularStd-BookItalic.woff2') format('woff2'),
        url('../fonts/CircularStd-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// Bold
@font-face {
    font-family: 'Circular Std';
    src: url('../fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../fonts/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}