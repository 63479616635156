// COLORS

$white:    #FFF;
$black:    #000;

$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #6C757D;
$gray-700: #54565B;
$gray-800: #343A40;
$gray-900: #212529;

$blue:    #007BFF;
$indigo:  #6610F2;
$purple:  #6F42C1;
$pink:    #E83E8C;
$red:     #DC3545;
$orange:  #FD7E14;
$yellow:  #FFC107;
$green:   #28A745;
$teal:    #20C997;
$cyan:    #17A2B8;
$beige:   #EDEBE7;
$military:#929A92;
$gold:    #AB9974;

$primary:       $military;
$secondary:     $gray-700;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
