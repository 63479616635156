* {
    &::selection {
        color: mix($white, $primary, 40%) !important;
    }

    &::-webkit-scrollbar {
        // width: 4px;
        width: 0;

        &:horizontal {
            // height: 4px;
            height: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: $gray-100;
        border-left: 1px solid $gray-300;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        outline: none;

        &:horizontal {
            background-color: $gray-100;

            &:hover {
                background-color: $gray-200;
            }
        }
    }
}

html {
    height: auto;
    scroll-behavior: initial;

    @include media("<=iPhoneProMax") {
        font-size: 10px;
    }

    @include media(">iPhoneProMax", "<=iPadPro") {
        font-size: 10px;
    }

    @include media(">=Laptop") {
        font-size: calc(100vw / 1440 * 10);
    }

    @include media(">=Desktop") {
        font-size: calc(100vw / 1920 * 10);
    }
}

body {
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    color: $white;
    background-color:$black;
    height: 100vh;
    width: 100%;
    // overflow: hidden;
    transition:
        color 1s ease-in-out,
        background-color 1s ease-in-out;

    --navigation-color: #{$white};
}

// Optional 'Visually Hide GRecaptcha'
.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
