$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);
$easeBouncing: cubic-bezier(0.75, -0.4, 0.25, 1.65);

html.loading {
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
