[data-navigation] {
    display: flex;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    padding: {
        top: 2rem;
        right: 2rem;
        bottom: 2rem;
        left: 2rem;
    }
    background-color: $gray-800;
    z-index: z("navigation");

    @include media(">=iPadLandscape") {
        position: fixed;
        justify-content: center;
        background: {
            color: transparent;
            image: linear-gradient(rgba($black, .25), rgba($black, 0))
        }
    }

    svg {
        display: block;
        height: auto;
        width: 150px;
        fill: $white;

        @include media(">=iPadLandscape") {
            width: 200px;
        }
    }
}
